*{
  box-sizing: border-box;
}
.flex {
  display: flex;
}
.footer-container{
  max-width: 1440px;
  margin: 0 auto;
}
.footer-section ul{
  padding: 0;
}

.footer-section {
  width: 100%;
  background-color: #f6f6f6;
  height: 100%;
}

.footer-top {
  width: 85%;
  display: flex;
  margin: 0 auto;
  /* justify-content: space-between; */
}

.footer-top-left {
  margin-right: 1vw;
}

.footer-top-right {
}

.kuants-logo {
  /* margin-left: 3vw; */
  max-width: 133px;
  max-height: 133px;
  margin-top: 5.5vw;
  margin-right: 6vw;
}

.image-logo {
  width: 100%;
  height: 100%;
}

.footer-section ul {
  list-style-type: none;
  margin-top: 0px;
}

li:first-child {
  margin-top: 0px;
}

.kuants-list {
  margin-top: 5.5vw;
  /* margin-right: 12.24vw; */
  margin-right: 0;
}

.heading {
  font-size: 24px;
  font-weight: 500;
  color: #333333;
  font-family: "Rubik";
}

.list-content {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  font-family: "Rubik";
  text-decoration: none;
}

.footer-section p {
  margin-top: 0px;
}

.app-list {
  margin-top: 5.5vw;
  margin-left: 4vw;
}

.follow-list {
  margin-top: 5.5vw;
  margin-left: 4vw;

}

.social-link li {
  display: inline;
}

.follow-list img {
  height: 42px;
  width: 42px;
}

.social-link ul {
  padding-inline-start: 0px;
}

.social-icon-container {
  display: flex;
  /* flex-wrap: wrap; */
}

.social-icon-container div {
  height: 52px;
  width: 52px;
  background: #ececec;
  margin-left: .8vw;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon-container div:first-of-type {
  margin-left: 0px;
  border-radius: 8px;
}



.footer-section hr {
  width: 90%;
  max-width: 1174px;
  margin-top: 2.78vw;
  opacity: 0.5;
  border: 1px solid #999999;
}

.footer-bottom {
  width: 81.52%;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-top: 46px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6.25vw;

}

.bottom-text {
  font-weight: 500;
  color: #000000;
}

.footer-bottom p:first-of-type {
  margin-top: 0px;
}

.footer-bottom-left p {
  margin-top: 33px;
}

.right-bottom-text {
  margin-top: 293px;
  /* margin-left: 13.26vw; */
}

.footer-bottom-left {
  width: 49.16vw;
}

.footer-bottom-right {
  margin-top: auto;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #666666;

}
.footer-bottom-right span:first-of-type{
  margin-right: 1rem;
}

@media only screen and (max-width:767px){
  .footer-top-left {
    flex-wrap: wrap;
    /* margin-right: 1vw; */
  }
  
  .footer-top-right {
    /* min-width: 50%; */
    flex-wrap: wrap-reverse;
  }
  .image-logo{
    height: 80px;
    width: 80px;
  }
  .kuants-logo{
    margin-right: 0;
    height: 80px;
    width: 80px;
  }
  .footer-top .follow-list{
    margin-left: 4vw;
    margin-top: 8vw;
  }
  .follow-list .heading{
    margin-bottom:3vw;
  }
  .social-icon-container{
    column-gap: 1vw;
  }
  .social-icon-container div {
    height: 35px;
    width: 35px;
  }
  .social-icon-container img{
    height: 28px;
    width: 28px;
  }
  .app-list{
    margin-top: auto;
  }
  .footer-bottom{
    margin-top: 30px;
  }
  .footer-bottom-left{
    flex-basis: 100%;
  }
  .app-list{
    margin-top: 2rem;
  }
  .footer-section p{
    margin-bottom: .5rem;
  }
  .footer-bottom p{
    margin-top: 15px;
    font-size: 14px;
  }
   .heading {
    font-size: 18px;
  }
  .kuants-list ul>p{
  font-size: 14px;
  }
  .list-content{
    font-size: 14px;
  }
}

@media (min-width:501px) and (max-width:767px){
  .footer-top .follow-list{
    margin-left: 4vw;
    margin-top: 10vw;
  }
  .footer-bottom-left{
    flex-basis: 100%;
  }
   
}
@media only screen and (max-width:991px){
  .footer-top{
    width: 87.5vw;
  }
  .footer-section hr{
    width: 87.5vw;
  }
  .follow-list{
    /* margin-left: 0; */
  }
  .kuants-logo{
    margin-left: 0;
  }
  .app-list{
    /* margin-top: auto; */
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  .kuants-logo{
    margin-right: 5vw;
  }
  .footer-top-right{
    margin-left: 2vw;
  }
}


@media (min-width:768px) and (max-width: 1440px) {
  .kuants-logo{
    margin-left: 0;
    margin-right: 4vw;
    
  }
  .image-logo{
    width: 9.236vw;
    height: 9.236vw;
  }
  .footer-top{
    width: 82%;
  }
  .footer-top-left{
    min-width: 50%;
  }
  .follow-list{
    margin-left: 3vw;
  }
  .app-list{
    margin-left: 0;
  }
  .social-icon-container>div{
    width:3.75vw;
    height: 3.75vw;
    /* align-items: flex-end; */
  }
  .follow-list img{
    height: 2.916vw;
    width: 2.916vw;
  }
  
}

@media only screen and (min-width: 1441px) {
  .footer-top{
    justify-content: left;
    max-width: 1182px;
  }
  .footer-bottom{
    /* justify-content: center; */
    padding-bottom: 80px;
  }
  .image-logo{
    height: 133px;
    width: 133px;
  }
  .footer-section hr{
    margin-left: 135px;
    margin-top: 46px;
  }
}