.content-crausel {
  max-width: 772px;
  /* max-height: 129px; */
  width: 53.61vw;
  /* height: 8.95vw; */
  /* margin-bottom: 8.81vw; */
  margin-bottom: 82.33px;
}
.content {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  color: #333333;
}
.content-crausel .slick-dots-bottom {
  margin-bottom: -42px;
  /* background-color: #333333; */
}
.content-crausel .slick-dots li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #d9d9d9;
  transform: matrix(1, 0, 0, -1, 0, 0);
}
.content-crausel .slick-dots li button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #d9d9d9;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.content-crausel .slick-dots li.slick-active {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #683fdb;
  transform: matrix(1, 0, 0, -1, 0, 0);
  
}
.content-crausel .slick-dots li.slick-active button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #683fdb;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.slick-list .slick-slide{
  height: 0;
  position: relative;
}
 .slick-list .slick-slide.slick-active{
  height: 100% !important;
 }