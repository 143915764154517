.partnership-main-container {
  /* margin-bottom: 8.33vw; */
  /* max-width:84vw; */
  /* gap: 5vw; */
  background: #683fdb;
  max-width: 1440px;
  margin: 0 auto 38px  auto;
  padding: 2.71vw 9.28vw 0 9.375vw;
  /* height: 47.56vw; */
  min-height: 685px;
}
.logo-rgster-btn-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-rgster-btn-cont button {
  width: 215px;
  height: 50px;
  border: 2px solid #ffffff;
  border-radius: 8px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  background: #683fdb;
  cursor: pointer;
}
.logo-rgster-btn-cont button:hover{
  background: #ff9900;
  border:unset;
  font-weight: 500
}
.form-content-top-cont {
  display: flex;
  justify-content: space-between;
  /* margin-top: 5.83vw; */
  flex-wrap: wrap;
  margin-top: 84.7px;
}
.form-content-top-cont .p-m-left {
  width: 36.61vw;
  max-width: 484px;
  margin: unset;
}

.partnership-main-container .partner-heading {
  width: 34.11vw;
  max-width: 484px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 3.05vw;
  line-height: 3.88vw;
  /* font-size: 44px;
  line-height: 56px; */
  letter-spacing: 0.1px;
  color: #ffffff;
}

.partnership-main-container .p-m-left p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  /* font-size: 1.25vw;
  line-height: 1.66vw; */
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  width: 34.11vw;
  max-width: 484px;
}
.form-content-top-cont .p-m-right{
  width: 42.27vw;
    max-width: 580px;
}
.p-m-right > h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  /* font-size: 1.94vw;
  line-height: 2.36vw; */
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.1px;
  color: #ffffff;
 margin: 0 0 32px 0;
}
.partner-dtl-frm {
  display: flex;
  flex-direction: column;
  /* gap: 1.38vw; */
  gap: 20px;
}
.partner-dtl-frm input {
  /* width: 40.27vw; */
  max-width: 580px;
  max-height: 54px;
  height: 3.75vw;
  min-height: 54px;
  background: #f7f7f7;
  border-radius: 10px;
  outline: none;
  border-style: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  /* font-size: 1.28vw;
  line-height: 2.5vw; */
  font-size: 20px;
line-height: 36px;
  padding: 0 0 0 25.62px;
}
.partner-dtl-frm input::placeholder {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  /* font-size: 1.28vw;
  line-height: 2.5vw; */
  font-size: 20px;
line-height: 36px;
  color: #666666;
}
.partner-dtl-frm select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  /* width: 40.27vw; */
  max-width: 580px;
  height: 3.75vw;
  max-height: 54px;
  min-height: 54px;
  background: #f7f7f7;
  border-radius: 10px;
  outline: none;
  border-style: none;
  padding: 0 0 0 25.62px;
  background-image: url("/public/assets/select-dwn-arrow.svg");
  background-position: 97.8%;
  background-repeat: no-repeat;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 1.38vw;
  line-height: 2.5vw;
  /* font-size: 20px;
  line-height: 36px; */
  color: #666666;
}

.crnt-aum-nf-clnt-slect-cont {
  display: flex;
  justify-content: space-between;
  /* width: 40.27vw; */
  max-width: 580px;
}
.crnt-aum-nf-clnt-slect-cont select {
  width: 19.14vw;
  max-width: 280px;
  height: 3.75vw;
  min-height: 54px;
  max-height: 54px;
  background: #f7f7f7;
  border-radius: 10px;
  background-image: url("/public/assets/select-dwn-arrow.svg");
  background-position: 95%;
  background-repeat: no-repeat;
}

.partner-dtl-frm .rqst-cll-btn {
  /* width: 40.27vw; */
  max-width: 580px;
  height: 4.16vw;
  min-height: 60px;
  max-height: 60px;
  background: #ff9900;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-style: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  /* font-size: 1.25vw;
  line-height: 1.45vw; */
  font-size: 18px;
line-height: 21px;
  color: #ffffff;
  cursor: pointer;
}
.partner-dtl-frm .rqst-cll-btn:hover{
  opacity: 0.9;

 
}

.testimonials-conainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 72px;
}
.profile-div {
  width: 145px;
  height: 145px;
  border-radius: 50%;
  margin-top: 27px;
}
.testimonials-conainer .card-title{
  margin-bottom: unset;
}

.landing-page-main-cont .callback-container{
  margin-bottom: 3.93vw;
}
.landing-page-main-cont .benefits .heading-style-one {
  margin-bottom: 2.16vw;
}
.landing-page-main-cont .card:first-child, .landing-page-main-cont .card:nth-child(2){
  width: 38%;
  /* background: red; */
}
 .landing-page-main-cont .card:nth-child(3){
  width: 30%;
 }
 .landing-page-main-cont .card:nth-child(4){
  width: 30%;
 }
 .landing-page-main-cont .card:nth-child(5){
  width: 30%;
 }
 
 .landing-page-main-cont .callback-container h2 {
  margin-bottom: 1.57vw;
 }


@media (min-width: 768px) and (max-width: 1024px) {
  .partnership-main-container .partner-heading{
    font-size: 3.05vw;
  line-height: 3.88vw;
  }
  .partner-dtl-frm select {
    font-size: 1.38vw;
    line-height: 2.5vw;
}
}



@media only screen and (min-width: 1441px) {
    .partnership-main-container{
        padding: 60px 130px 0 135px;
    }
    .partnership-main-container .partner-heading{
         font-size: 44px;
  line-height: 56px;
    }
    .partner-dtl-frm select {
        font-size: 20px;
  line-height: 36px;
    }
  .ptnrShp-form {
    width: 481px;
    height: 325px;
    gap: 47px;
  }
}
@media only screen and (max-width: 767px) {
  .partnership-main-container {
    /* flex-wrap: wrap; */
    /* height: 150vw; */
    
  }
  .logo-rgster-btn-cont button {
    width: 110px;
    height: 40px;
    font-size: 14px;
    line-height: 16px;
  }
  .logo-rgster-btn-cont button:hover{
   font-size: 15px;
    line-height: 19px;
  }
  .form-content-top-cont {
    flex-direction: column;
  }
  .form-content-top-cont .p-m-left {
    display: flex;
    width: 81.61vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: unset;
  }
  .partnership-main-container .partner-heading{
    font-size: 44px;
    line-height: 56px;
    text-align: center;
    width: 70vw;
}
.partnership-main-container .p-m-left p{
  width: 70vw;
  text-align: center;
}
  
  .form-content-top-cont .p-m-right{
    display: flex;
    width: 81.61vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: unset;
    padding-bottom: 8vw;
  }
  .p-m-right > h1{
    text-align: center ;
  }
  .partner-dtl-frm input{
    width: 70vw;
  }
  .partner-dtl-frm select{
    width: 70vw;
    font-size: 2.88vw;
    line-height: 2.5vw;
  }
  .crnt-aum-nf-clnt-slect-cont{
    width: 70vw;
  }
  .crnt-aum-nf-clnt-slect-cont select{
    width: 33vw;
  }
  .partner-dtl-frm .rqst-cll-btn{
    width: 70vw;
  }






  .landing-page-main-cont .card:first-child, .landing-page-main-cont .card:nth-child(2){
    width: 100%;
    /* background: red; */
  }
   .landing-page-main-cont .card:nth-child(3){
    width: 100%;
   }
   .landing-page-main-cont .card:nth-child(4){
    width: 100%
   }
   .landing-page-main-cont .card:nth-child(5){
    width: 100%;
   }
   .content-crausel{
    width: 88.18vw !important;
   }
}
