*{
box-sizing: border-box;
}
.flex {
    display: flex;
}
li a {
    text-decoration: none;
}
.blogsContainer {
    text-align: center;
    max-width: 1440px;
    width: 95%;
    margin: 0 auto;
}
.blogsContainer h1 {
    font-family: 'Rubik';
    font-weight: 400;
    font-size:38px;
}
.blogsContainer>span {
    color: rgb(106, 106, 106);
    font-weight: 600;
}

.bottomContainer {
    display: flex;
    margin-top: 4.5vw;
    margin-bottom: 5vw;
}
.articleCategories {
    height: fit-content;
    position: sticky;
    top: 5vw;
    margin-right: 1.5vw;
    max-width: 220px;
    text-align: center;
}
.booksIcon {
    width: 6rem;
}
.articleCategories ul {
    padding: 0;
    width: max-content;
}
.articleCategories li a {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
}
.articleCategories li a:hover {
    color: #0e66c4;
    text-decoration: underline;
}
.articleCategories li {
    border-bottom: 1px solid #dee2e6;
    padding: 10px 25px;
}
.articlesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    row-gap: 3vw;
}
.blogCard {
    height: fit-content;
    max-width: 340px;
    background: #FFFFFF;
    box-shadow: 0px 14px 26px 3px rgb(0 0 0 / 10%);
    cursor: pointer;
    border-radius: 12px;
    padding: 1.18vw;
    box-sizing: border-box;
    text-align: left;
}
.blogCard a{
    text-decoration: none;
}
.blogCard img {
    width: 100%;
    background-size: contain;
}
.blogCard h4 {
    padding: 0 10px;
    font-size: 20px;
    font-weight: 500;
    color: #000;
}
.blogCard:hover  h4{
    text-decoration: underline;
    color: #007bff;
}
.blogCard span{
    margin-right: .5vw;
    background: rgb(238, 62, 62);
    border-radius: .5vw;
    padding: .5vw;
    font-size: 12px;
    color: #FFFFFF;
}
.tags{
    margin-left: 2vw;
    display: flex;
    flex-wrap: wrap;
}

.blogContainer {
    text-align: center;
    width: 95%;
    margin: 0 auto;
    display: flex;
}
.subNavblog a{
    text-decoration: none;
    color: #0275d8;
    margin: 0 10px;
}
.subNavblog a:last-child{
color: #000;
}
.blogTitle{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Rubik';
    margin-bottom: 5vw;
}


@media only screen and (max-width: 1200px) {
    .blogCard {
        max-width: 230px;
    }
    .blogCard h4{
        font-size: 18px;
    }
    .articlesContainer{
        justify-content: space-evenly;
        row-gap: 3vw;
        column-gap: 1vw;
    }
}

@media only screen and (max-width:465px) {
    .bottomContainer{
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 5vw;
    }
    
    .blogsContainer h1{
        font-size: 38px;
    }
    .blogCard {
        /* max-width: 200px; */
    }
    .blogCard h4{
        font-size: 16px;
        padding: 0 6px;
    }
    .booksIcon{
        width: 4rem;
    }
    .articleCategories{
        max-width: 100%;
        display: none;
    }
    .articleCategories li a{
        font-size: 15px;
    }
    .articleCategories li{
        padding: 5px 12.5px;
    }
}
