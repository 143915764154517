.success-pop-up {
  width: 687px !important;
  height: 413px;
  border-radius: 16px;
}
.success-pop-up .ant-modal-close-x {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #666666;
}
.success-pop-up .ant-modal-body {
  /* display: flex;
    justify-content: center;
    align-items: center; */
}
.success-pop-up .main-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 88px 0 88px 0;
}
.success-pop-up .main-cont > h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #333333;
  opacity: 0.8;
  margin: 34px 0 0 0;
}
.success-pop-up .main-cont > h4 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #333333;
  margin: 10px 0 0 0;
}
.success-pop-up .ant-modal-footer {
  display: none;
}
